import React, { useState } from 'react';
import { MenuItem, Select, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

function DataSelect({
  selectType,
  value,
  setOnChange,
  onOpen,
  optionList,
  disabled,
  loading,
  placeHolder,
  textFormatter,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <InputLabel id={`${selectType}Label`}>{selectType}</InputLabel>
      <Select
        labelId={`${selectType}Label`}
        id={`${selectType}Select`}
        value={value || 'None'}
        onChange={(e) => setOnChange(e.target.value)}
        onOpen={() => {
          setOpen(true);
          onOpen();
        }}
        onClose={() => setOpen(false)}
        disabled={disabled}
        open={open}
        style={{ width: '15em' }}
      >
        <MenuItem key="none" value="None" disabled>
          <em>{loading && open ? 'Loading' : placeHolder}</em>
        </MenuItem>
        {optionList.map((i) => (
          <MenuItem key={i} value={i}>
            {textFormatter(i)}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

DataSelect.propTypes = {
  selectType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setOnChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  optionList: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  placeHolder: PropTypes.string.isRequired,
  textFormatter: PropTypes.func,
};

DataSelect.defaultProps = {
  disabled: false,
  loading: false,
  textFormatter: (e) => e,
};

export default DataSelect;
