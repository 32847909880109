import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useLazyQuery } from '@apollo/react-hooks';
import Layout from '../components/layout';
import Navigator from '../components/navigator';
import AuthenticatedPage from '../components/authenticated-page';
import { getToken } from '../utils/utils';
import { LIST_RISK_REPORT, GET_RISK_REPORT_LINK } from '../crud';
import { DataSelect } from '../components/download-report';

function DownloadReport() {
  const token = getToken();

  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedVasp, setSelectedVasp] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('');

  const [monthOptions, setMonthOptions] = useState([]);
  const [vaspOptions, setVaspOptions] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);

  const [getRiskReportList, riskReportList] = useLazyQuery(LIST_RISK_REPORT, {
    variables: { prefix: undefined },
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      const prefixLevel = (riskReportList.variables.prefix || '').split('/').length;
      switch (prefixLevel) {
        case 1:
          setMonthOptions(data.listRiskReport);
          break;
        case 2:
          setVaspOptions(data.listRiskReport);
          break;
        case 3:
          setProviderOptions(data.listRiskReport);
          break;
        default:
          throw new Error('invalid prefixLevel');
      }
    },
  });
  const [getRiskReportLink] = useLazyQuery(GET_RISK_REPORT_LINK, {
    variables: {
      fileKey: `${selectedMonth}/${selectedVasp}/${selectedProvider}`,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      window.open(data.getRiskReportLink, '_blank');
    },
  });

  const handleClick = (prefix) => {
    // concat corrent prefix
    getRiskReportList({
      variables: { prefix },
      context: {
        headers: {
          authorization: token,
        },
      },
    });
  };

  return (
    <Layout>
      <AuthenticatedPage>
        <Navigator
          breadcrumbTexts={['Home', 'Download Report']}
          breadcrumbLinks={['/', '/download-report']}
        />
        <h2>High Risk Address Report for promotion</h2>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <DataSelect
              selectType="Month"
              value={selectedMonth}
              setOnChange={(v) => {
                setSelectedVasp('');
                setSelectedProvider('');
                setSelectedMonth(v);
              }}
              onOpen={() => handleClick()}
              optionList={monthOptions}
              loading={riskReportList.loading}
              placeHolder="Choose year and month"
              textFormatter={(e) => `${e.slice(0, 4)}/${e.slice(4, 6)}`}
            />
          </Grid>
          <Grid item>
            <DataSelect
              selectType="Vasp"
              value={selectedVasp}
              setOnChange={(v) => {
                setSelectedVasp(v);
                setSelectedProvider('');
              }}
              onOpen={() => handleClick(`${selectedMonth}/`)}
              optionList={vaspOptions}
              disabled={!selectedMonth}
              loading={riskReportList.loading}
              placeHolder="Choose a VASP"
            />
          </Grid>
          <Grid item>
            <DataSelect
              selectType="Provider"
              value={selectedProvider}
              setOnChange={setSelectedProvider}
              onOpen={() => handleClick(`${selectedMonth}/${selectedVasp}/`)}
              optionList={providerOptions}
              disabled={!selectedMonth || !selectedVasp}
              loading={riskReportList.loading}
              placeHolder="Choose a KYT vender"
              textFormatter={(e) => {
                const cap = e.charAt(0).toUpperCase() + e.slice(1);
                return cap.split('.')[0];
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              disabled={!selectedMonth || !selectedVasp || !selectedProvider}
              onClick={getRiskReportLink}
            >
              Download
            </Button>
          </Grid>
        </Grid>
      </AuthenticatedPage>
    </Layout>
  );
}

export default DownloadReport;
